import logo from './logo.svg';
import './App.css';
import Router from './Router/Router';
import './assets/css/style.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
function App() {
  return (
    <div className="App">
    <Router />
    </div>
  );
}

export default App;
