import React from 'react'

export default function Header() {
    return (
        <div className='header'>
            <div className='logo'>
                <img src={require('../assets/images/logo1.png')} />
            </div>
            <ul>
                <li><a href='https://www.facebook.com/mehandipurbalajidausa' target='_blank'><img src={require('../assets/images/fb.png')} /></a></li>
                <li><a href='https://www.youtube.com/@ShriMehandipurBalajiapp' target='_blank'><img src={require('../assets/images/y.png')} /></a></li>
                <li><a href='https://www.instagram.com/mehandipurbalajidausa/' target='_blank'><img src={require('../assets/images/insta.png')} /></a></li>
                <li><a href='https://chat.whatsapp.com/Kw3fa7hh518FdhMBwomXZM' target='_blank'><img src={require('../assets/images/what.png')} /></a></li>
            </ul>
        </div>
    )
}
