import React from 'react'
import Animation from './Animation'

export default function Home() {
    return (
        <main>
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="left_content">
                            <span>Welcome to Mehandipur Bala Ji App</span>
                            <h1>Mahendipur Balaji</h1>
                            <hr />
                                <p>Welcome to the divine realm of Mehandipur Balaji, an app dedicated to Lord Balaji of Mehandipur, Rajasthan. Immerse yourself in the blissful aura of spirituality as you embark on a transformative journey with our comprehensive features</p>
                                <div class="banner_section_btn">
                                    <a href="https://play.google.com/store/apps/details?id=com.mehandipurbalaji" target="_blank">
                                        <img src={require('../assets/images/get_it_on_google_play.png')} alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/in/app/mehandipur-balaji/id6450503422" target="_blank">
                                        <img src={require('../assets/images/app_store.png')} alt="" />
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                       <div className='shape_box'>
                           <img src={require('../assets/images/hanumanji_m.png')} />
                       </div>
                    </div>
                </div>
            </div>
            </main>
        )
}
