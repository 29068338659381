import React from 'react'

export default function Animation() {
    return (
        <div class="fixed">
            <div class="fall">
                <span>जय  श्री राम</span>
                <span>जय सिया राम</span>
                <span>राम राम जय श्री राम</span>
                <span>राम राम जी </span>
                <span>जय रघुवीर</span>
                <span>जय  श्री राम</span>
                <span>जय सिया राम</span>
                <span>राम राम जय श्री राम</span>
                <span>राम राम जी </span>
                <span>जय रघुवीर</span>
                <span>जय  श्री राम</span>
                <span>जय सिया राम</span>
                <span>राम राम जय श्री राम</span>
                <span>राम राम जी </span>
                <span>जय रघुवीर</span>
                <span>जय  श्री राम</span>
                <span>जय सिया राम</span>
                <span>राम राम जय श्री राम</span>
                <span>राम राम जी </span>
                <span>जय रघुवीर</span>
                <span>राम</span>
                <span>राम</span>
                <span>राम</span>
                <span>राम</span>
                <span>राम</span>
                <span>राम</span>
            </div>
        </div>
    )
}
