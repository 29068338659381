import React, { useEffect, useState } from 'react'
import Header from './Header'
import Animation from './Animation'
import { io } from 'socket.io-client';
import Odometer from 'react-odometerjs';
import "../assets/css/meter.scss";

export default function Index() {

    return (
        <div className='wrapper'>
            <Animation />
            <Header />
            <div className='main'>
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/YourLiveStreamID" frameborder="0" allowfullscreen></iframe> */}
                <div className='left-section'>
                    <div className='first-section'>
                        <div className='one_bg_img'>
                            <img src={require('../assets/images/ci.png')} className='bg-img-one' />
                            <img src={require('../assets/images/circle-design.png')} className='circle-design' />
                            <img src={require('../assets/images/cloude2.png')} className='cloude2' />
                            <img src={require('../assets/images/cloude1.png')} className='cloude1' />

                        </div>
                        {/* <div className='shap_mask'> */}
                        <img src={require('../assets/images/hanumanji.png')} className='main_img' />
                        {/* </div> */}
                        {/* <div className='total_counter'>
                            <h2>rama naama sa/kIQtana</h2>
                        </div> */}
                    </div>
                </div>
                <div className='middle-section'>
                    <div className='ram-naam-count'>
                        <img src={require('../assets/images/homepage.png')} />
                        <div className='ram-naam-card-box'>
                           
                        </div>
                    </div>
                </div>
                <div className='right-section'>
                    <div className='right-content'>
                        <img src={require('../assets/images/logo.png')} />
                        <h1>rama naama sa/kIQtana</h1>
                        <p> "राम नाम" अंकित करने वाले भक्त को<br />
                            प्रति सप्ताह दिया जाएगा एक विशेष पुरस्कार,<br />
                            चयनित होने के लिए प्रतिदिन राम नाम करें।</p>
                    </div>
                    <div className='download_app'>
                        <h4>Download App</h4>
                        <ul>
                            <li><a href='https://play.google.com/store/apps/details?id=com.mehandipurbalaji' target='_blank'><img src={require('../assets/images/get_it_on_google_play.png')} /></a></li>
                            <li><a href='https://apps.apple.com/in/app/mehandipur-balaji/id6450503422' target='_blank'><img src={require('../assets/images/app_store.png')} /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
